import axios from "axios";
import { IUser, IRole } from "@/types/User";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *User service
 * Provides UI business logic for user
 */
export class UserService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IServiceResponse> {
    try {
      //console.log("getUser:", this.API_URL);
      const result: any = await axios.get(`${this.API_URL}/User`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async getRoles(): Promise<IRole[]> {
    const result: any = await axios.get(`${this.API_URL}/Role`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
  public async getRoleByUserId(id: string): Promise<any> {
    const result: any = await axios.get(
      `${this.API_URL}/Role/GetByUserId/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      }
    );
    return result.data;
  }
  public async create(user: IUser) {
    try {
      const result = await axios.post(`${this.API_URL}/User`, user, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async edit(user: IUser) {
    try {
      const result = await axios.post(`${this.API_URL}/User/Update`, user, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async delete(id: string): Promise<{
    data: any;
    time: Date;
    message: string;
    isSuccess: boolean;
  }> {
    try {
      const result = await axios.get(`${this.API_URL}/User/delete/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async login(user: IUser): Promise<IServiceResponse> {
    try {
      const result: IServiceResponse = await axios.post(
        `${this.API_URL}/auth/login`,
        user
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText ?? ""}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async logout(user: IUser) {
    const result: any = await axios.post(`${this.API_URL}/auth/logout`, user);
    return result.data;
  }
}
