import axios from "axios";
import { IWarehouse } from "@/types/Warehouse";
import { ITransporter } from "@/types/Transporter";
/*
 *Warehouse service
 * Provides UI business logic for warehouse
 */
export class WarehouseService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IWarehouse[]> {
    //console.log("getWarehouse:", this.API_URL);
    const result: any = await axios.get(`${this.API_URL}/Warehouse`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
  public async create(warehouse: IWarehouse) {
    try {
      const result = await axios.post(`${this.API_URL}/Warehouse`, warehouse, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
