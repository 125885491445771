import axios from "axios";
import { IDistrict } from "@/types/District";
/*
 *District service
 * Provides UI business logic for district
 */
export class DistrictService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IDistrict[]> {
    //console.log("getDistrict:", this.API_URL);
    const result: any = await axios.get(`${this.API_URL}/District`, {
      headers: { "Authorization": "Bearer " + localStorage.getItem("token") || "{}" },
    });
    return result.data;
  }
}
