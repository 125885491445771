import axios from "axios";
import { ICommodity } from "@/types/Commodity";
import { IDispatch } from "@/types/Dispatch";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *Commodity service
 * Provides UI business logic for commodity
 */
export class CommodityService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<ICommodity[]> {
    //console.log("getCommodity:", this.API_URL);
    const result: any = await axios.get(`${this.API_URL}/Commodity`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
  public async create(commodity: ICommodity): Promise<IServiceResponse> {
    try {
      const result = await axios.post(`${this.API_URL}/Commodity`, commodity, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
