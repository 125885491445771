
import { Component, Vue } from "vue-property-decorator";
import { LoadingPlanService } from "@/services/loading-plan-service";
import { WarehouseService } from "@/services/warehouse-service";
import { DispatchService } from "@/services/dispatch-service";
import { CommodityService } from "@/services/commodity-service";
import { TruckDriverService } from "@/services/truck-driver-service";
import { DistrictService } from "@/services/district-service";
import { TransporterService } from "@/services/transporter-service";
import { UserService } from "@/services/user-service";
import { ILoadingPlan } from "@/types/LoadingPlan";
import { IDispatch } from "@/types/Dispatch";
import { ITruckDriver } from "@/types/TruckDriver";
import { IDistrict } from "@/types/District";
import { IWarehouse } from "@/types/Warehouse";
import { ITransporter } from "@/types/Transporter";
import { ICommodity } from "@/types/Commodity";
import { IUser } from "@/types/User";
import newDispatchModal from "@/components/DispatchModal.vue";
import viewLoadingPlanModal from "@/components/ViewLoadingPlanModal.vue";
import newLoadingPlanModal from "@/components/LoadingPlanModal.vue";
import newRequisitionModal from "@/components/DisasterModal.vue";
import confirmDeleteModal from "@/components/ConfirmDeleteModal.vue";
import editLoadingPlanModal from "@/components/EditLoadingPlanModal.vue";
import { Defaults } from "@/helpers/Defaults";
import { ActivityService } from "@/services/activity-service";
import { IActivity } from "@/types/Activity";
import { Functions } from "@/helpers/Functions";
import { IRequestParams } from "@/types/RequestParams";
import { RequisitionService } from "@/services/requisition-service";
import { IRequisition } from "@/types/Requisition";
import { ProjectService } from "@/services/project-service";
import { IProject } from "@/types/Project";

@Component({
  name: "LoadingPlan",
  components: {
    newLoadingPlanModal,
    newRequisitionModal,
    newDispatchModal,
    viewLoadingPlanModal,
    confirmDeleteModal,
    editLoadingPlanModal,
  },
})
export default class LoadingPlan extends Vue {
  show = { showOverlay: false }; //this is for new loading plan
  edit = { showOverlay: false }; // this is for loading plans
  dispatchOverlay = { show: false };
  confirmDelete = { showOverlay: false };
  newRequisitionShow = { showOverlay: false };
  userString = localStorage.getItem("user");
  isAdmin = false;
  defaults = new Defaults();
  functions = new Functions();
  loadingPlanService = new LoadingPlanService();
  requisitionService = new RequisitionService();
  dispatchService = new DispatchService();
  driverService = new TruckDriverService();
  districtService = new DistrictService();
  transporterService = new TransporterService();
  warehouseService = new WarehouseService();
  commodityService = new CommodityService();
  userService = new UserService();
  activityService = new ActivityService();
  projectService = new ProjectService();
  myLoadingPlans: ILoadingPlan[] = [];
  allLoadingPlans: ILoadingPlan[] = [];
  requisitions: IRequisition[] = [];
  users: IUser[] = [];
  activities: IActivity[] = [];
  projects: IProject[] = [];
  loadingPlansCount = 0;
  pageSize = 10;
  pageNumber = 1;
  currentModal = {
    id: 0,
    name: "",
    showOverlay: false,
  };
  currentDate = new Date();
  loadingPlan = this.defaults.loadingPlan;
  dispatch = this.defaults.dispatch;
  driver: ITruckDriver = {
    id: 0,
    name: "",
    licence: "",
    isArchived: false,
  };
  drivers: ITruckDriver[] = [];
  districts: IDistrict[] = [];
  warehouses: IWarehouse[] = [];
  transporters: ITransporter[] = [];
  commodities: ICommodity[] = [];
  loadingPlanFilters = this.defaultFilters;
  loading = true;
  loadingPlansSkeleton = true;
  requisitionsSkeleton = true;
  requisitionsCountSkeleton = true;
  get groupedActivities(): any {
    return this.functions.groupBy(this.activities, (i) => i.project.name);
  }
  async initialize(): Promise<void> {
    const includes =
      '["Commodity","Warehouse","Transporter","District","User","Activity","Dispatches"]';
    const loadingPlanResponse = await this.loadingPlanService.getAll(includes);
    if (!loadingPlanResponse.isSuccess && loadingPlanResponse.status == 401)
      this.visitRoute("/logout");
    this.allLoadingPlans = loadingPlanResponse.data.data;
    this.loadingPlansCount = loadingPlanResponse.data.count;
    this.pageSize = loadingPlanResponse.data.requestParams.pageSize;
    this.pageNumber = loadingPlanResponse.data.requestParams.pageNumber;
    this.myLoadingPlans = this.allLoadingPlans;
    this.loadingPlan = this.myLoadingPlans[0];
    if (this.$route.path == "/loading-plan/pending") {
      let loadingPlans = this.allLoadingPlans;
      let result = loadingPlans.filter(function (loadingPlan) {
        if ((loadingPlan.balance || 0) > 0) return loadingPlan;
      });
      this.allLoadingPlans = result;
      this.myLoadingPlans = result;
      this.loadingPlan = this.myLoadingPlans[0];
    }
    this.loading = false;
    this.loadingPlansSkeleton = false;
    const requisitionResponse = await this.requisitionService.getAll();
    this.requisitions
    this.requisitionsCountSkeleton = false;
    this.requisitionsSkeleton = false;
    this.drivers = await this.driverService.getAll();
    this.districts = await this.districtService.getAll();
    this.transporters = await this.transporterService.getAll();
    this.warehouses = await this.warehouseService.getAll();
    this.commodities = await this.commodityService.getAll();
    let userResponse = await this.userService.getAll();
    this.users = userResponse.data;
    this.activities = await this.activityService.getAll();
    this.projects = await this.projectService.getAll();
    this.loadingPlan.activity = this.activities[1];
  }
  async created(): Promise<void> {
    this.checkIfAdmin();
    await this.initialize();
  }
  dispatched(loadingPlan: ILoadingPlan): number {
    let dispatches = loadingPlan.dispatches ?? [];
    let dispatched = dispatches.reduce((qty: number, item) => {
      return qty + item.quantity;
    }, 0);
    return +(dispatched / 20).toFixed(2);
  }
  checkIfAdmin(): void {
    if (this.userString != null) {
      const user = JSON.parse(this.userString);
      const userRoles = user.roles;
      if (userRoles.includes("admin")) {
        this.isAdmin = true;
      }
    }
  }
  closeModal(id: number): void {
    const modal = this.deleteLoadingPlanModalId(id);
    this.$bvModal.hide(modal.name);
  }
  get defaultFilters() {
    return {
      minDate: "",
      maxDate: "",
      district: this.defaults.district,
      transporter: this.defaults.transporter,
      warehouse: this.defaults.warehouse,
    };
  }
  clearFilters(): void {
    this.myLoadingPlans = this.allLoadingPlans;
    this.loadingPlanFilters = this.defaultFilters;
  }
  filterLoadingPlans(): void {
    const allLoadingPlans = this.allLoadingPlans;
    const myFilters = this.loadingPlanFilters;
    const defaultDistrict = this.defaults.district;
    const isDistrictDefault =
      JSON.stringify(myFilters.district) != JSON.stringify(defaultDistrict);
    const defaultTransporter = this.defaults.transporter;
    const isTransporterDefault =
      JSON.stringify(myFilters.transporter) !=
      JSON.stringify(defaultTransporter);
    const defaultWarehouse = this.defaults.warehouse;
    const isWarehouseDefault =
      JSON.stringify(myFilters.warehouse) != JSON.stringify(defaultWarehouse);
    //const defaultCommodity = this.defaults.commodity;
    this.myLoadingPlans = allLoadingPlans.filter(function (
      selectedLoadingPlan
    ) {
      let toFilter = true;
      if (myFilters.minDate != "") {
        toFilter =
          toFilter &&
          new Date(selectedLoadingPlan.startDate || new Date()) >=
            new Date(myFilters.minDate);
      }
      if (toFilter && myFilters.maxDate != "") {
        toFilter =
          toFilter &&
          new Date(selectedLoadingPlan.startDate || "1970-01-01") <=
            new Date(myFilters.maxDate);
      }
      if (toFilter && isDistrictDefault) {
        toFilter =
          toFilter && selectedLoadingPlan.district.id == myFilters.district.id;
      }
      if (toFilter && isTransporterDefault) {
        toFilter =
          toFilter &&
          selectedLoadingPlan.transporter.id == myFilters.transporter.id;
      }
      if (toFilter && isWarehouseDefault) {
        toFilter =
          toFilter &&
          selectedLoadingPlan.warehouse.id == myFilters.warehouse.id;
      }
      if (toFilter) {
        return selectedLoadingPlan;
      }
    });
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
  dispatchModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "createDispatchModal" + i,
      showOverlay: false,
    };
  }
  viewLoadingPlanModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewLoadingPlanModal" + i,
      showOverlay: false,
    };
  }
  deleteLoadingPlanModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "deleteLoadingPlanModal" + i,
      showOverlay: false,
    };
  }
  editLoadingPlanModalId(item: ILoadingPlan): {
    id: number;
    name: string;
    showOverlay: boolean;
    dispatched: number;
  } {
    return {
      id: item.id,
      name: "editLoadingPlanModal" + item.id,
      showOverlay: false,
      dispatched: this.dispatched(item),
    };
  }
  makeToast(
    message = "Oh Snap! something went wrong. Please try again later.",
    title = "",
    variant = "danger"
  ): void {
    this.$bvToast.toast(message, {
      title: title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant,
    });
  }
  async deleteLoadingPlan(id: number): Promise<void> {
    if (this.isAdmin) {
      this.confirmDelete.showOverlay = true;
      const result = await this.loadingPlanService.delete(id);
      this.confirmDelete.showOverlay = false;
      this.$bvModal.hide(this.deleteLoadingPlanModalId(id).name);
      let message = result.message;
      let title = "Deleting Loading Plan";
      let variant = "danger";
      if (result.isSuccess) {
        variant = "success";
        const loadingPlanResponse = await this.loadingPlanService.getAll();
        this.allLoadingPlans = loadingPlanResponse.data;
        this.myLoadingPlans = this.allLoadingPlans;
        this.loadingPlan = this.myLoadingPlans[0];
        if (this.$route.path == "/loading-plan/pending") {
          let loadingPlans = this.allLoadingPlans;
          let result = loadingPlans.filter(function (loadingPlan) {
            if ((loadingPlan.balance || 0) > 0) return loadingPlan;
          });
          this.allLoadingPlans = result;
          this.myLoadingPlans = result;
          this.loadingPlan = this.myLoadingPlans[0];
        }
      }
      this.makeToast(message, title, variant);
    } else {
      this.$bvModal.hide(this.deleteLoadingPlanModalId(id).name);
      let message = "You can't update this resource!";
      let title = "Deleting Loading Plan";
      let variant = "danger";
      this.makeToast(message, title, variant);
    }
  }
  async updateLoadingPlan(loadingPlan: ILoadingPlan): Promise<void> {
    this.edit = { showOverlay: true };
    const result = await this.loadingPlanService.update(loadingPlan);
    let message = result.message;
    let title = "Updating Loading Plan";
    let variant = "danger";
    if (result.isSuccess) {
      variant = "success";
    }
    this.edit = { showOverlay: false };
    const currentModal = this.editLoadingPlanModalId(loadingPlan);
    this.$bvModal.hide(currentModal.name);
    this.makeToast(message, title, variant);
  }
  async submitNewLoadingPlan(loadingPlan: ILoadingPlan): Promise<void> {
    this.show = { showOverlay: true };
    loadingPlan.commodity = loadingPlan.commodity || this.defaults.commodity;
    loadingPlan.warehouse = loadingPlan.warehouse || this.defaults.warehouse;
    loadingPlan.transporter =
      loadingPlan.transporter || this.defaults.transporter;
    loadingPlan.district = loadingPlan.district || this.defaults.district;
    loadingPlan.commodityId = loadingPlan.commodity.id;
    loadingPlan.warehouseId = loadingPlan.warehouse.id;
    loadingPlan.transporterId = loadingPlan.transporter.id;
    loadingPlan.districtId = loadingPlan.district.id;
    const result = await this.loadingPlanService.create(loadingPlan);
    let message = result.message;
    let title = "Creating a new Loading Plan";
    let variant = "danger";
    if (result.isSuccess) {
      this.allLoadingPlans = this.prepend(result.data, this.allLoadingPlans);
      this.myLoadingPlans = this.allLoadingPlans;
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.$bvModal.hide("createLoadingPlanModal");
    this.makeToast(message, title, variant);
  }
  async submitNewDispatch(dispatch: IDispatch): Promise<void> {
    dispatch.loadingPlan = dispatch.loadingPlan || this.defaults.loadingPlan;
    this.currentModal = this.dispatchModalId(dispatch.loadingPlan.id);
    this.dispatchOverlay.show = true;
    const result = await this.dispatchService.create(dispatch);
    this.dispatchOverlay.show = false;
    this.$bvModal.hide(this.currentModal.name);
    let message = result.message;
    let title = "Creating a new Dispatch";
    let variant = "danger";
    if (result.isSuccess) {
      this.dispatch = this.defaults.dispatch;
      variant = "success";
    }
    this.makeToast(message, title, variant);
  }
  async submitNewRequisition(requisition: IRequisition): Promise<void> {
    this.newRequisitionShow = { showOverlay: true };
    const result = await this.requisitionService.create(requisition);
    console.log(result.status);
    if (!result.isSuccess && result.status == 401) this.visitRoute("/logout");
    this.$bvModal.hide("disasterModal");
    let message = result.message;
    let title = "Creating a new Requisition";
    let variant = "danger";
    if (result.isSuccess) {
      message = "New requisition created successfully";
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.makeToast(message, title, variant);
  }
  prepend(value: any, array: Array<any>): Array<any> {
    let newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }
}
