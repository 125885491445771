import axios from "axios";
import { ITruckDriver } from "@/types/TruckDriver";
/*
 *Truck Driver service
 * Provides UI business logic for truck driver
 */
export class TruckDriverService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<ITruckDriver[]> {
    const result: any = await axios.get(`${this.API_URL}/TruckDriver`, {
      headers: { "Authorization": "Bearer " + localStorage.getItem("token") || "{}" },
    });
    return result.data;
  }
  public async create(driver: ITruckDriver) {
    try {
      const result = await axios.post(`${this.API_URL}/TruckDriver`, driver, {
        headers: { "Authorization": "Bearer " + localStorage.getItem("token") || "{}" }
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
