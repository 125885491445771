import axios from "axios";
import { ITransporter } from "@/types/Transporter";

/*
 *Transporter service
 * Provides UI business logic for transporter
 */
export class TransporterService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<ITransporter[]> {
    //console.log("getTransporter:", this.API_URL);
    const result = await axios.get(`${this.API_URL}/Transporter`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
  public async create(transporter: ITransporter) {
    try {
      const result = await axios.post(
        `${this.API_URL}/Transporter`,
        transporter,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}"
          }
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async delete(id: number) {
    try {
      const res = await axios.delete(`${this.API_URL}/Transporter`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
        data: {
          source: id,
        },
      });
      return res.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
